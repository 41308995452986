<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Systemlog</v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="text"
          label="Søg"
          placeholder="Skriv for at søge"
          solo
          hide-details
        />

        <v-menu
          v-model="showPeriod"
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template #activator="{ attrs, on }">
            <v-text-field
              :value="dateRangeText"
              label="Periode"
              readonly
              v-bind="attrs"
              v-on="on"
              solo
              hide-details
              class="ml-3"
            />
          </template>

          <v-date-picker
            v-model="query.period"
            range
            show-adjacent-months
            no-title
            show-current
            first-day-of-week="1"
            @change="getLogs"
          />
        </v-menu>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="logs"
          :items-per-page="20"
          sort-by="createdOn"
          sort-desc
          multi-sort
          :search="text"
          :style="{ fontFamily: 'monospace', maxWidth: '100%' }"
        >
          <template #[`item.createdOn`]="{ value }">
            {{ moment(value).format('YYYY-MM-DD hh:mm:ss') }}
          </template>
          <template #[`item.level`]="{ value }">
            <v-chip small label :color="value.toLowerCase()">{{ value }}</v-chip>
          </template>
          <template #[`item.message`]="{ item }">
            <div v-if="item.event === 'SupplierPartImport'" class="d-flex justify-space-between align-center">
              <div>
                {{ item.message.slice(0,110) }}...
              </div>

              <v-dialog scrollable>
                <template #activator="{ attrs, on }">
                  <v-btn
                    outlined
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Show more
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    Import details
                    <v-chip class="ml-4" label :color="item.level.toLowerCase()">
                      {{ item.level }}
                    </v-chip>
                  </v-card-title>
                  <v-card-text class="text-caption">
                    <div v-for="(line, index) in item.message.split(/\r?\n/)" :key="index" class="d-flex" style="font-family: monospace;">
                      <span style="width: 30px;">{{ index + 1 }}</span>
                      <span>{{ line }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <div v-else-if="item.message.length > 1000" class="d-flex justify-space-between align-center">
              <div>
                {{ item.message.slice(0,110) }}...
              </div>

              <v-dialog scrollable>
                <template #activator="{ attrs, on }">
                  <v-btn
                    outlined
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Show more
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    Import details
                    <v-chip class="ml-4" label :color="item.level.toLowerCase()">
                      {{ item.level }}
                    </v-chip>
                  </v-card-title>
                  <v-card-text class="text-caption">
                    <div v-for="(line, index) in item.message.split(/\r?\n/)" :key="index" class="d-flex" style="font-family: monospace;">
                      <span style="width: 30px;">{{ index + 1 }}</span>
                      <span>{{ line }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
            <div v-else>
              {{ item.message }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SystemLog',
  data() {
    return {
      logs: [],
      text: null,
      query: {
        period: [this.moment().subtract(1, 'days').toISOString(), this.moment().toISOString()],
        from: this.moment().subtract(3, 'days').toISOString(),
        to: this.moment().toISOString()
      },
      showPeriod: false
    }
  },
  computed: {
    headers() {
      return [
        { text: 'Tid', value: 'createdOn', width: '10%' },
        { text: 'Level', value: 'level', width: '5%' },
        { text: 'Event', value: 'event' },
        { text: 'Emne', value: 'sourceEntityType' },
        { text: 'Besked', value: 'message', width: '60%' },
      ]
    },
    dateRangeText() {
      const from = this.query.period[0] ? new Date(this.query.period[0]).toLocaleDateString() : ''
      const to = this.query.period[1] ? new Date(this.query.period[1]).toLocaleDateString() : ''
      return from + ' - ' + to
    }
  },
  methods: {
    getLogs: async function() {
      this.loading = true

      try {
        const { data } = await this.$AuthService.api.get('log', {
          params: this.query
        })

        this.logs = data
      } catch (error) {
        console.error(error)
        this.$notifier.showError({ message: 'Der skete en fejl!' })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getLogs()
  }
}
</script>
